import React from 'react';
import ComingSoon from 'components/page-coming-soon';
import Meta from 'meta';

export default function ComingSoonPage() {
  return (
    <>
      <Meta
      title="CXN Collective | Digital Collective in Miami, DC, Latin America, and More"
      description="Mark your company’s place in the modern world with top digital marketing, design, and web development teams cementing your online presence."
      canonical="https://cxncollective.com/"
      />
      <ComingSoon />
;
    </>
  );
}
