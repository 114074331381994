import React from 'react';
import Headline from 'headline';
import HeroWrapper from 'shared/hero-wrapper';
import Button from 'button';

import s from './page-coming-soon.module.scss';

export default function ComingSoon() {
  return (
    <HeroWrapper img="cxn-hero-404" className={s.hero}>
      <div className={s.content}>
        <Headline dash center h1 white className={s.title}>
          COMING SOON...
        </Headline>
        <p>
          If you want a vacation, visit Miami Beach. Meanwhile, let’s get you
          back on track.
        </p>
        <Button hoverWhite href="/">Back to homepage</Button>
      </div>
    </HeroWrapper>
  );
}
